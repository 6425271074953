import ToggleButton from 'components/ToggleButton';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const Row = styled.div`
  margin: 0 -6px;
  overflow-x: auto;
  display: flex;
`;

const StyledToggleButton = styled(ToggleButton)`
  margin: 6px;
  width: 56px;
  height: 56px;
  flex-shrink: 0;
`;

interface IScoresListProps {
  onSelectScore: (score: number) => void;
  selectedScore?: number;
}

const Scores = Array.from(Array(10).keys()).map((x) => ++x);

const ScoresList: React.FC<IScoresListProps> = ({
  onSelectScore,
  selectedScore,
}) => {
  const rowRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // Scroll selected score into view
    if (selectedScore && rowRef.current) {
      const child = rowRef.current.children[selectedScore - 1] as
        | HTMLElement
        | undefined;

      const offset = child?.offsetLeft;
      const childWidth = child?.offsetWidth;
      const width = rowRef.current.offsetWidth;

      if (
        offset != null &&
        width != null &&
        childWidth != null &&
        offset > width
      ) {
        rowRef.current.scrollLeft = offset + childWidth - width;
      }
    }
  }, []);

  return (
    <Row ref={rowRef}>
      {Scores.map((score) => (
        <StyledToggleButton
          key={score}
          onClick={() => onSelectScore(score)}
          selected={selectedScore === score}
        >
          {score}
        </StyledToggleButton>
      ))}
    </Row>
  );
};

export default ScoresList;
