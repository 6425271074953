import { ratePlayers } from 'api/rate';
import AppLoader from 'components/AppLoader';
import Button from 'components/Button';
import Container from 'components/Container';
import { Divider } from 'components/Divider';
import ScoresList from 'components/shared/ScoresList';
import Text from 'components/Text';
import { FontFamily } from 'enums/FontFamily';
import useAlert from 'hooks/useAlert';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useStores from 'stores/helpers/useStores';
import styled, { useTheme } from 'styled-components';
import { IPlayer } from 'types/Player';
import { ROUTE } from 'utils/routes';

const StyledContainer = styled(Container)`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SaveButton = styled(Button)`
  align-self: center;
`;

const RatingContainer = styled.div`
  display: flex;
  margin-top: 24px;
  margin-bottom: 24px;

  > div {
    flex: 1;
  }
`;

const InlineText = styled(Text)`
  display: inline;
`;

interface IPlayerDetailsProps {
  player: IPlayer;
}

const PlayerDetails: React.FC<IPlayerDetailsProps> = ({ player }) => {
  const { playerStore, authStore } = useStores();
  const theme = useTheme();
  const navigate = useNavigate();
  const alert = useAlert();

  const [loading, setLoading] = useState(false);

  const [rating, setRating] = useState<number | undefined>();

  const isCurrentUser = player.id === authStore.profile?.playerId;

  const updateRating = async () => {
    if (!rating || isCurrentUser) {
      return;
    }

    setLoading(true);
    try {
      await ratePlayers({
        ratings: [
          {
            playerId: player.id,
            rating,
          },
        ],
      });
      alert.success('Player rating updated');
      playerStore.loadPlayers();
      navigate(ROUTE.HOME);
    } catch (e) {
      alert.error('Failed to update rating');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Container pt={32}>
        <Text
          variant="headline3"
          color={theme.palette.dark[200]}
          textTransform="uppercase"
        >
          Score
        </Text>

        <div>
          <InlineText
            variant="custom"
            family={FontFamily.JetbrainsMono}
            color={theme.palette.dark[800]}
            size={36}
            weight={700}
          >
            {player.score?.toFixed(1) ?? '-'}
          </InlineText>
          <InlineText
            variant="custom"
            family={FontFamily.JetbrainsMono}
            color={theme.palette.dark[100]}
            weight={700}
          >
            {' '}
            / 10
          </InlineText>
        </div>

        <Text variant="p2" mt={8} mb={24}>
          Overall Player score is calculated from player's win/loss ratio and
          other player's votes.
        </Text>

        <Divider />

        <RatingContainer>
          <div>
            <Text
              variant="headline3"
              color={theme.palette.dark[200]}
              textTransform="uppercase"
            >
              Win/Loss ratio
            </Text>
            <Text
              variant="custom"
              weight={700}
              family={FontFamily.JetbrainsMono}
              color={theme.palette.dark[800]}
              size={16}
              mb={4}
            >
              {player.winRatio?.toFixed(1) ?? '-'}
            </Text>
          </div>
          <div>
            <Text
              variant="headline3"
              color={theme.palette.dark[200]}
              textTransform="uppercase"
              mb={4}
            >
              Other player's votes
            </Text>
            <Text
              variant="custom"
              weight={700}
              family={FontFamily.JetbrainsMono}
              color={theme.palette.dark[800]}
              size={16}
            >
              {player.rating?.toFixed(1) ?? '-'}
            </Text>
          </div>
        </RatingContainer>
      </Container>
      <Divider />
      {!isCurrentUser && (
        <StyledContainer py={32}>
          <div>
            <Text variant="h6" mb={4} color={theme.palette.dark[800]}>
              Your vote
            </Text>
            <Text variant="p2" mb={24}>
              Select a number from 1 to 10 and tap "Update rating” to update
              your vote.
            </Text>

            <ScoresList
              onSelectScore={setRating}
              selectedScore={rating || player.userRating}
            />
          </div>

          <SaveButton
            disabled={!rating || rating === player.userRating}
            onClick={updateRating}
            width="l"
          >
            Update rating
          </SaveButton>

          {loading && <AppLoader />}
        </StyledContainer>
      )}
    </>
  );
};

export default observer(PlayerDetails);
