import { ITheme } from 'theme/types';

export const Theme: ITheme = {
  palette: {
    primary: {
      50: '#E5E8EF',
      100: '#C3CBDC',
      200: '#92A0BD',
      300: '#687CA2',
      400: '#486291',
      500: '#254881',
      600: '#1E4179',
      700: '#15386E',
      800: '#0E2F61',
      900: '#05204A',
    },
    secondary: {
      50: '#FFF3E0',
      100: '#FFE0B2',
      200: '#FFCC80',
      300: '#FFB74D',
      400: '#FFA726',
      500: '#FF9800',
      600: '#FB8C00',
      700: '#F57C00',
      800: '#EF6C00',
      900: '#E65100',
    },
    light: {
      50: '#FFFFFF',
      100: '#FAFAFA',
      200: '#FAFAFA',
      300: '#FAFAFA',
      400: '#FAFAFA',
      500: '#FAFAFA',
      600: '#FAFAFA',
      700: '#FAFAFA',
      800: '#FAFAFA',
      900: '#FAFAFA',
    },
    dark: {
      50: '#E8EBED',
      100: '#CFD8DC',
      200: '#B0BEC5',
      300: '#90A4AE',
      400: '#78909C',
      500: '#607D8B',
      600: '#546E7A',
      700: '#455A64',
      800: '#37474F',
      900: '#263238',
    },
    warning: {
      50: '#FFF3E0',
      100: '#FFE0B2',
      200: '#FFCC80',
      300: '#FFB74D',
      400: '#FFA726',
      500: '#FF9800',
      600: '#FB8C00',
      700: '#F57C00',
      800: '#EF6C00',
      900: '#E65100',
    },
    danger: {
      50: '#FFE9EC',
      100: '#FFC7CC',
      200: '#F48F8F',
      300: '#E86365',
      400: '#EF3D3F',
      500: '#F02622',
      600: '#E21622',
      700: '#D0011D',
      800: '#C30016',
      900: '#B40008',
    },
    success: {
      50: '#E8F5E9',
      100: '#C8E6C9',
      200: '#A5D6A7',
      300: '#81C784',
      400: '#66BB6A',
      500: '#4CAF50',
      600: '#43A047',
      700: '#388E3C',
      800: '#2E7D32',
      900: '#1B5E20',
    },
  },
  typography: {
    h1: {
      fontSize: '36px',
      lineHeight: '44px',
      fontWeight: 600,
    },
    h2: {
      fontSize: '32px',
      lineHeight: '40px',
      fontWeight: 600,
    },
    h3: {
      fontSize: '28px',
      lineHeight: '36px',
      fontWeight: 600,
    },
    h4: {
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: 600,
    },
    h5: {
      fontSize: '20px',
      lineHeight: '28px',
      fontWeight: 600,
    },
    h6: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 600,
    },
    h7: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 600,
    },
    h8: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 600,
    },
    p1: {
      fontSize: '14px',
      lineHeight: '28px',
    },
    p2: {
      fontSize: '12px',
      lineHeight: '24px',
    },
    headline1: {
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 600,
      letterSpacing: '1px',
    },
    headline2: {
      fontSize: '12px',
      lineHeight: '20px',
      fontWeight: 600,
      letterSpacing: '0.6px',
    },
    headline3: {
      fontSize: '10px',
      lineHeight: '16px',
      fontWeight: 600,
      letterSpacing: '0.2px',
    },
    button: {
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 600,
    },
    input1: {
      fontSize: '16px',
      lineHeight: '28px',
    },
    input2: {
      fontSize: '14px',
      lineHeight: '24px',
    },
    inputLabel: {
      fontSize: '12px',
      lineHeight: '32px',
    },
    inputSupport: {
      fontSize: '12px',
      lineHeight: '20px',
    },
    notification: {
      fontSize: '12px',
      lineHeight: '20px',
      fontWeight: 600,
    },
    custom: {
      lineHeight: 1.2,
    },
  },
  contentMaxWidth: '900px',
};
