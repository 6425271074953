import { ReactComponent as ChevronRightIcon } from 'assets/chevron-right.svg';
import Button from 'components/Button';
import Container from 'components/Container';
import Text from 'components/Text';
import { FontFamily } from 'enums/FontFamily';
import { PlayerListType } from 'enums/PlayerListType';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { IPlayer } from 'types/Player';
import { ROUTE } from 'utils/routes';

const Wrapper = styled.div`
  border-top: 1px solid ${(props) => props.theme.palette.dark[50]};
  background-color: ${(props) => props.theme.palette.light[50]};
  display: flex;
  justify-content: center;
`;

const StyledContainer = styled(Container)<{ clickable?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
`;

const UserScore = styled.div`
  width: 130px;
  padding-left: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
`;

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
`;

const InlineText = styled(Text)`
  display: inline;
`;

interface IPlayerScoreItemProps {
  player: IPlayer;
  position: number;
  type: PlayerListType;
}

const PlayerScoreItem: React.FC<IPlayerScoreItemProps> = ({
  player,
  position,
  type,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const goToPlayer = () => {
    navigate(`${ROUTE.PLAYERS}/${player.id}`);
  };

  const isMainList = type === PlayerListType.Main;

  return (
    <Wrapper>
      <StyledContainer
        py={20}
        onClick={isMainList ? goToPlayer : undefined}
        clickable={isMainList}
      >
        <Text
          variant="headline2"
          textTransform="uppercase"
          color={theme.palette.dark[800]}
        >
          {isMainList && `${position}. `}
          {player.firstName} {player.lastName}
        </Text>

        {isMainList ? (
          <UserScore>
            <div>
              <InlineText
                variant="custom"
                family={FontFamily.JetbrainsMono}
                color={theme.palette.dark[800]}
                size={16}
                weight={700}
              >
                {player.score?.toFixed(1) ?? '-'}
              </InlineText>
              <InlineText
                variant="custom"
                family={FontFamily.JetbrainsMono}
                color={theme.palette.dark[100]}
                size={10}
                weight={700}
              >
                {' '}
                / 10
              </InlineText>
            </div>

            <IconWrapper>
              <ChevronRightIcon stroke={theme.palette.primary[500]} />
            </IconWrapper>
          </UserScore>
        ) : (
          <Button onClick={goToPlayer} size="small" width="auto">
            Rate now
          </Button>
        )}
      </StyledContainer>
    </Wrapper>
  );
};

export default observer(PlayerScoreItem);
