import Container from 'components/Container';
import ScoresList from 'components/shared/ScoresList';
import Text from 'components/Text';
import React from 'react';
import useStores from 'stores/helpers/useStores';
import styled from 'styled-components';
import { IPlayer } from 'types/Player';

const Wrapper = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.palette.dark[50]};
  display: flex;
  justify-content: center;
`;

interface IRateItemProps {
  index: number;
  player: IPlayer;
}

const RateItem: React.FC<IRateItemProps> = ({ index, player }) => {
  const { playerStore } = useStores();

  const onScorePress = (score: number) => {
    playerStore.setPlayerScore(player.id, score);
  };

  return (
    <Wrapper>
      <Container py={24}>
        <Text variant="h8" textTransform="uppercase">
          {index}. {player.firstName} {player.lastName}
        </Text>

        <ScoresList
          onSelectScore={onScorePress}
          selectedScore={player.localRating}
        />
      </Container>
    </Wrapper>
  );
};

export default RateItem;
