import { ReactComponent as InfoIcon } from 'assets/info.svg';
import AppLoader from 'components/AppLoader';
import Banner from 'components/Banner';
import Button from 'components/Button';
import { Card, CardBody, CardFooter } from 'components/Card';
import FooterNav from 'components/Nav/FooterNav';
import Navbar from 'components/Nav/Navbar';
import PlayerScoreList from 'components/PlayerScore/PlayerScoreList';
import Text from 'components/Text';
import { PlayerListType } from 'enums/PlayerListType';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useStores from 'stores/helpers/useStores';
import styled from 'styled-components';
import { pluralize } from 'utils/pluralize';
import { ROUTE } from 'utils/routes';

const Wrapper = styled.div`
  flex: 1;
  background-color: ${(props) => props.theme.palette.light[100]};
`;

const Home: React.FC = () => {
  const navigate = useNavigate();
  const { playerStore } = useStores();

  useEffect(() => {
    if (!playerStore.players.length) {
      playerStore.loadPlayers();
    }
  }, []);

  return (
    <Wrapper>
      <Navbar />

      {playerStore.ratedPlayers.length > 0 &&
        playerStore.unratedPlayers.length > 0 && (
          <Banner
            Icon={InfoIcon}
            title="Unrated players"
            description={`You have ${pluralize(
              playerStore.unratedPlayers.length,
              'player'
            )} to rate`}
            link={ROUTE.UNRATED_PLAYERS}
          />
        )}

      {!playerStore.ratedPlayers.length && (
        <Card>
          <CardBody>
            <Text variant="h6">Welcome to Povio Football</Text>
            <Text variant="p2">Complete the rating process to get started</Text>
          </CardBody>
          <CardFooter>
            <Button
              onClick={() => {
                navigate(ROUTE.RATE);
              }}
              width="m"
            >
              Start rating
            </Button>
          </CardFooter>
        </Card>
      )}

      {playerStore.ratedPlayers.length > 0 && (
        <>
          <PlayerScoreList
            players={playerStore.players}
            title="Overall player score"
            type={PlayerListType.Main}
          />

          <PlayerScoreList
            players={playerStore.unratedPlayers}
            title="Unrated"
            type={PlayerListType.Unrated}
          />
        </>
      )}

      <FooterNav />

      {playerStore.loading && <AppLoader />}
    </Wrapper>
  );
};

export default observer(Home);
