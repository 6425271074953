import { ReactComponent as EyeOffIcon } from 'assets/eye-off.svg';
import { ReactComponent as EyeOnIcon } from 'assets/eye-on.svg';
import Text from 'components/Text';
import React, { useMemo, useState } from 'react';
import styled, { useTheme } from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
`;

const InputContainer = styled.div`
  position: relative;
`;

const StyledInput = styled.input<{ error: boolean }>`
  padding: 10px 16px;
  width: 100%;
  border: ${({ error, theme }) =>
    error
      ? `2px solid ${theme.palette.danger[400]}`
      : `1px solid ${theme.palette.dark[100]}`};
  border-radius: 8px;

  font-size: ${(props) => props.theme.typography['input1']?.fontSize};
  line-height: ${(props) => props.theme.typography['input1']?.lineHeight};
  font-weight: ${(props) => props.theme.typography['input1']?.fontWeight};
  color: ${(props) => props.theme.palette.dark[900]};

  ::placeholder {
    color: ${(props) => props.theme.palette.dark[200]};
  }

  &:focus {
    border-color: ${({ error, theme }) =>
      error ? theme.palette.danger[400] : theme.palette.primary[500]};
    outline: 0;
  }
`;

const IconContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  padding: 8px;
  cursor: pointer;
`;

export interface IInputProps {
  type?: 'email' | 'text' | 'password';
  name: string;
  value?: string;
  placeholder?: string;
  label?: string;
  onChange?: (e: React.ChangeEvent) => void;
  onBlur?: (e: React.FocusEvent) => void;
  errorText?: string;
  helpText?: string;
}

const Input: React.FC<IInputProps> = ({
  type,
  name,
  value,
  placeholder,
  label,
  onChange,
  onBlur,
  errorText,
  helpText,
}) => {
  const theme = useTheme();
  const id = useMemo(
    () => `${name}-${Math.floor(Math.random() * 1000)}`,
    [name]
  );
  const [showPassword, setShowPassword] = useState(false);

  const inputType = type === 'password' && showPassword ? 'text' : type;
  const EyeIcon = showPassword ? EyeOnIcon : EyeOffIcon;

  return (
    <Wrapper>
      {label && (
        <Text
          variant="inputLabel"
          htmlFor={id}
          color={
            errorText ? theme.palette.danger[400] : theme.palette.dark[900]
          }
        >
          {label}
        </Text>
      )}
      <InputContainer>
        <StyledInput
          id={id}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          error={!!errorText}
          type={inputType}
        ></StyledInput>
        {type === 'password' && (
          <IconContainer onClick={() => setShowPassword(!showPassword)}>
            <EyeIcon />
          </IconContainer>
        )}
      </InputContainer>
      {helpText && !errorText && (
        <Text variant="inputSupport" color={theme.palette.dark[200]} mt={6}>
          {helpText}
        </Text>
      )}
      {errorText && (
        <Text variant="inputSupport" color={theme.palette.danger[400]} mt={6}>
          {errorText}
        </Text>
      )}
    </Wrapper>
  );
};

export default Input;
