import SummaryItem from 'components/Summary/SummaryItem';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import useStores from 'stores/helpers/useStores';
import { ROUTE } from 'utils/routes';

const SummaryList: React.FC = () => {
  const navigate = useNavigate();
  const { playerStore } = useStores();

  const players = useMemo(
    () => playerStore.unratedPlayers.filter((p) => !!p.localRating),
    [playerStore.unratedPlayers]
  );

  useEffect(() => {
    if (!players?.length) {
      navigate(ROUTE.RATE);
    }
  }, [players]);

  return (
    <>
      {players.map((player, index) => (
        <SummaryItem key={player.id} player={player} index={index + 1} />
      ))}
    </>
  );
};

export default observer(SummaryList);
