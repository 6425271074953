import AuthStore from 'stores/AuthStore';
import MatchStore from 'stores/MatchStore';
import PlayerStore from 'stores/PlayerStore';

export default class RootStore {
  authStore: AuthStore;
  playerStore: PlayerStore;
  matchStore: MatchStore;

  constructor() {
    this.authStore = new AuthStore(this);
    this.playerStore = new PlayerStore(this);
    this.matchStore = new MatchStore(this);
  }

  reset() {
    this.authStore.reset();
    this.playerStore.reset();
    this.matchStore.reset();
  }
}
