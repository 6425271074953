import defaultAxios, { AxiosRequestConfig } from 'axios';
import { API_URL } from 'config/Config';
import {
  getAccessToken,
  isAccessTokenValid,
  removeAccessToken,
} from 'utils/accessToken';

const axios = defaultAxios.create({
  baseURL: API_URL,
});

axios.interceptors.request.use((request) => {
  if (isAccessTokenValid() && !request?.headers?.['Authorization']) {
    request.headers = {
      ...request.headers,
      Authorization: `Bearer ${getAccessToken()}`,
    };
  }
  return request;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      removeAccessToken();
    }
    return Promise.reject(error);
  }
);

interface ApiRequestOptions<T> extends AxiosRequestConfig {
  data?: T;
  params?: T;
}

export const apiRequest = async <D = Record<string, unknown>, R = unknown>(
  method: 'get' | 'post' | 'patch' | 'delete',
  path: string,
  options?: ApiRequestOptions<D>
) => {
  const res = await axios.request<R>({ method, url: path, ...options });
  return res.data;
};

export default axios;
