import { ReactComponent as CheckIcon } from 'assets/check.svg';
import Button from 'components/Button';
import Footer from 'components/Footer';
import Text from 'components/Text';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useStores from 'stores/helpers/useStores';
import styled, { useTheme } from 'styled-components';
import { ROUTE } from 'utils/routes';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 0 24px;
`;

const StyledCheckIcon = styled(CheckIcon)`
  margin-bottom: 32px;
`;

const ForgotPasswordSucccess: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { authStore } = useStores();

  useEffect(() => {
    if (!authStore.email) {
      navigate(ROUTE.LOGIN);
    }
  }, []);

  const onContinue = () => {
    navigate(ROUTE.LOGIN);
  };

  return (
    <>
      <Wrapper>
        <StyledCheckIcon />
        <Text variant="h4" color={theme.palette.dark[800]} mb={16}>
          Check your inbox
        </Text>
        <Text variant="p1" color={theme.palette.dark[700]}>
          A reset password link was sent to
        </Text>
        <Text variant="p1" weight="600" color={theme.palette.dark[700]} mb={8}>
          {authStore.email}
        </Text>
      </Wrapper>
      <Footer noBorder>
        <Button onClick={onContinue} width="m">
          Go to login
        </Button>
      </Footer>
    </>
  );
};

export default observer(ForgotPasswordSucccess);
