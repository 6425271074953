import RateItem from 'components/Rate/RateItem';
import { observer } from 'mobx-react-lite';
import React from 'react';
import useStores from 'stores/helpers/useStores';

const RateList: React.FC = () => {
  const { playerStore } = useStores();

  return (
    <>
      {playerStore.unratedPlayers.map((player, index) => (
        <RateItem key={player.id} player={player} index={index + 1} />
      ))}
    </>
  );
};

export default observer(RateList);
