import { resetPassword } from 'api/user';
import AppLoader from 'components/AppLoader';
import Button from 'components/Button';
import Container from 'components/Container';
import Footer from 'components/Footer';
import FormikInput from 'components/FormikInput';
import Header from 'components/Header';
import Navbar from 'components/Nav/Navbar';
import { Formik } from 'formik';
import useQuery from 'hooks/useQuery';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ROUTE } from 'utils/routes';
import * as Yup from 'yup';

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

interface FormValues {
  password: string;
  passwordConfirm: string;
}

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Password must include at least 8 characters')
    .required('Password is required'),
  passwordConfirm: Yup.string()
    .required('Confirm password is required')
    .when('password', {
      is: (value: string) => {
        return value && value.length > 0;
      },
      then: Yup.string().oneOf(
        [Yup.ref('password'), undefined],
        'Passwords do not match'
      ),
    }),
});

const ResetPassword: React.FC = () => {
  const params = useQuery();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!params.get('token')) {
      navigate(ROUTE.LOGIN);
    }
  }, [params]);

  const onSubmit = async (values: FormValues) => {
    const token = params.get('token');
    if (!token) {
      return;
    }

    setLoading(true);

    try {
      await resetPassword({
        resetPassword: { password: values.password, resetToken: token },
      });

      navigate(ROUTE.LOGIN);
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <>
      <Navbar />

      <Header title="Reset password" noBorder />

      <Formik
        initialValues={{ password: '', passwordConfirm: '' }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isValid, dirty }) => (
          <form onSubmit={handleSubmit}>
            <StyledContainer>
              <FormikInput
                name="password"
                label="New password"
                type="password"
                placeholder="Enter your desired password"
                helpText="Password must include at least 8 characters"
              />
              <FormikInput
                name="passwordConfirm"
                label="Confirm password"
                type="password"
                placeholder="Confirm your password"
              />
            </StyledContainer>

            <Footer noBorder>
              <Button type="submit" width="m" disabled={!dirty || !isValid}>
                Change password
              </Button>
            </Footer>
          </form>
        )}
      </Formik>

      {loading && <AppLoader />}
    </>
  );
};

export default ResetPassword;
