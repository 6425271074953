import { createContext } from 'react';
import { IAlertOptions } from './AlertProvider';

export interface AlertContextType {
  info: (text: string, options?: IAlertOptions) => void;
  success: (text: string, options?: IAlertOptions) => void;
  warning: (text: string, options?: IAlertOptions) => void;
  error: (text: string, options?: IAlertOptions) => void;
  close: () => void;
  open?: boolean;
}

export const AlertContext = createContext<AlertContextType>(
  {} as AlertContextType
);
