import { getMe, ProfileResponse } from 'api/user';
import { makeAutoObservable } from 'mobx';
import RootStore from 'stores/RootStore';

export default class AuthStore {
  rootStore: RootStore;
  profile: ProfileResponse | null = null;
  email: string | null = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  reset() {
    this.profile = null;
    this.email = null;
  }

  setUserProfile(profile: ProfileResponse | null) {
    this.profile = profile;
  }

  async loadUserProfile() {
    try {
      const profile = await getMe();
      this.setUserProfile(profile);
    } catch (e) {
      this.setUserProfile(null);
    }
  }

  setEmail(email: string | null) {
    this.email = email;
  }
}
