import Container from 'components/Container';
import MatchListItem from 'components/Match/MatchListItem';
import Text from 'components/Text';
import groupBy from 'lodash/groupBy';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import useStores from 'stores/helpers/useStores';
import styled, { useTheme } from 'styled-components';

const Wrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 40px;
  }
`;

const MatchList: React.FC = () => {
  const theme = useTheme();
  const { matchStore } = useStores();

  const groups = useMemo(() => {
    return groupBy(matchStore.matches, 'monthYear');
  }, [matchStore.matches]);

  const hasMore =
    matchStore.totalCount === undefined ||
    matchStore.matches.length < matchStore.totalCount;

  useEffect(() => {
    // Infinite scroll will not trigger loadMore if initial content doesn't cause overflow.
    if (
      matchStore.matches.length > 1 &&
      document.body.clientHeight === document.body.scrollHeight &&
      hasMore
    ) {
      loadMore();
    }
  }, [matchStore.matches]);

  const loadMore = () => {
    if (!matchStore.loading && hasMore) {
      matchStore.loadMatches();
    }
  };

  return (
    <InfiniteScroll
      initialLoad={false}
      threshold={10}
      loadMore={loadMore}
      hasMore={hasMore}
    >
      {Object.entries(groups).map(([key, value]) => (
        <Wrapper key={key}>
          <Container>
            <Text
              variant="headline3"
              textTransform="uppercase"
              color={theme.palette.dark[200]}
              mb={12}
            >
              {key}
            </Text>
          </Container>

          {value.map((match) => (
            <MatchListItem key={match.id} match={match} />
          ))}
        </Wrapper>
      ))}
    </InfiniteScroll>
  );
};

export default observer(MatchList);
