import AppLoader from 'components/AppLoader';
import Button from 'components/Button';
import Footer from 'components/Footer';
import Header from 'components/Header';
import RateList from 'components/Rate/RateList';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useStores from 'stores/helpers/useStores';
import { ROUTE } from 'utils/routes';

const Rate: React.FC = () => {
  const navigate = useNavigate();
  const { playerStore } = useStores();

  const canContinue = () => {
    return playerStore.unratedPlayers.some((player) => !!player.localRating);
  };

  const onContinue = () => {
    navigate(ROUTE.SUMMARY);
  };

  useEffect(() => {
    if (!playerStore.players.length) {
      playerStore.loadPlayers();
    }
  }, []);

  return (
    <>
      <Header
        back
        title="Rating"
        description="Complete the rating process by selecting a number below each player. Tap “Continue” to confirm your vote."
      />
      <RateList />
      <Footer>
        <Button onClick={onContinue} disabled={!canContinue()} width="m">
          Continue
        </Button>
      </Footer>

      {playerStore.loading && <AppLoader />}
    </>
  );
};

export default observer(Rate);
