import React, { ReactNode, useState } from 'react';
import { StoreContext } from 'stores/helpers/StoreContext';
import RootStore from 'stores/RootStore';

interface IProps {
  children: ReactNode;
}

const StoreProvider: React.FC<IProps> = ({ children }) => {
  const [rootStore] = useState<RootStore>(new RootStore());

  return (
    <StoreContext.Provider
      value={{
        rootStore,
        authStore: rootStore.authStore,
        playerStore: rootStore.playerStore,
        matchStore: rootStore.matchStore,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export default StoreProvider;
