import React, { ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<{ noBorder?: boolean }>`
  padding: 24px 0;
  border-top: ${(props) =>
    !props.noBorder && `1px solid ${props.theme.palette.dark[50]}`};
  background-color: ${(props) => props.theme.palette.light[50]};

  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;

  display: flex;
  justify-content: center;
`;

const Placeholder = styled.div<{ height: number; noBorder?: boolean }>`
  width: 100%;
  height: ${(props) => props.height - (props.noBorder ? 0 : 1)}px;
  flex-shrink: 0;
`;

interface IFooterProps {
  children: ReactNode;
  className?: string;
  noBorder?: boolean;
}

const Footer: React.FC<IFooterProps> = ({ children, className, noBorder }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      setHeight(ref.current?.offsetHeight || 0);
    });
    resizeObserver.observe(ref.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [ref]);

  return (
    <>
      <Placeholder height={height} noBorder={noBorder} />
      <Wrapper className={className} ref={ref} noBorder={noBorder}>
        {children}
      </Wrapper>
    </>
  );
};

export default Footer;
