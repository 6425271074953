import { ReactComponent as ChevronRightIcon } from 'assets/chevron-right.svg';
import Button from 'components/Button';
import Container from 'components/Container';
import Text from 'components/Text';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useStores from 'stores/helpers/useStores';
import styled, { useTheme } from 'styled-components';
import { IMatch } from 'types/Match';
import { ROUTE } from 'utils/routes';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.palette.light[50]};
  border-top: 1px solid ${(props) => props.theme.palette.dark[50]};

  &:last-child {
    border-bottom: 1px solid ${(props) => props.theme.palette.dark[50]};
  }
`;

const StyledContainer = styled(Container)<{ clickable: boolean }>`
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledIconWrapper = styled.div`
  display: flex;
  padding-left: 16px;
`;

const Status = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;

const WinStatus = styled(Status)`
  background-color: ${(props) => props.theme.palette.success[400]};
`;

const LoseStatus = styled(Status)`
  background-color: ${(props) => props.theme.palette.danger[400]};
`;

interface IProps {
  match: IMatch;
}

const MatchListItem: React.FC<IProps> = ({ match }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { authStore } = useStores();

  const goToMatch = () => {
    navigate(`${ROUTE.MATCH}/${match.id}`);
  };

  const renderStatus = () => {
    if (!match.played) {
      return (
        <Text
          variant="headline3"
          textTransform="uppercase"
          color={theme.palette.dark[200]}
        >
          Result pending
        </Text>
      );
    } else if (match.isWinner) {
      return (
        <WinStatus>
          <Text
            variant="headline3"
            textTransform="uppercase"
            color={theme.palette.light[50]}
          >
            W
          </Text>
        </WinStatus>
      );
    } else if (match.isLoser) {
      return (
        <LoseStatus>
          <Text
            variant="headline3"
            textTransform="uppercase"
            color={theme.palette.light[50]}
          >
            L
          </Text>
        </LoseStatus>
      );
    }

    return (
      <Text
        variant="headline3"
        textTransform="uppercase"
        color={theme.palette.dark[200]}
      >
        Didn't play
      </Text>
    );
  };

  const canAddResult = authStore.profile?.admin && !match.played;

  const renderAction = () => {
    if (canAddResult) {
      return (
        <Button onClick={goToMatch} width="xs" size="small">
          Add result
        </Button>
      );
    }

    return (
      <ActionContainer>
        {renderStatus()}
        <StyledIconWrapper>
          <ChevronRightIcon stroke={theme.palette.primary[500]} />
        </StyledIconWrapper>
      </ActionContainer>
    );
  };

  return (
    <Wrapper>
      <StyledContainer
        onClick={!canAddResult ? goToMatch : undefined}
        clickable={!canAddResult}
      >
        <Text variant="headline2" color={theme.palette.dark[800]}>
          {DateTime.fromFormat(match.date, 'yyyy-MM-dd').toFormat('d. M. yyyy')}
        </Text>

        {renderAction()}
      </StyledContainer>
    </Wrapper>
  );
};

export default observer(MatchListItem);
