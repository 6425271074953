import { ReactComponent as JerseyIcon } from 'assets/jersey.svg';
import { ReactComponent as Logo } from 'assets/logo.svg';
import NavLink from 'components/Nav/NavLink';
import React from 'react';
import { Link } from 'react-router-dom';
import useStores from 'stores/helpers/useStores';
import styled, { useTheme } from 'styled-components';
import { ROUTE } from 'utils/routes';

const Wrapper = styled.div`
  padding: 14px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme.palette.primary[700]};
`;

const NavLinks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 16px;
`;

const AvatarImg = styled.img`
  width: 32px;
  height: 32px;
  display: block;
  border-radius: 16px;
  border: 2px solid ${(props) => props.theme.palette.primary[500]};
`;

const StyledLogo = styled(Logo)`
  display: block;
`;

const Navbar: React.FC = () => {
  const theme = useTheme();
  const { authStore, playerStore } = useStores();

  return (
    <Wrapper>
      <Link to={ROUTE.HOME}>
        <StyledLogo />
      </Link>

      {authStore.profile && (
        <NavLinks>
          <NavLink
            to={ROUTE.UNRATED_PLAYERS}
            badge={playerStore.unratedPlayers.length > 0}
          >
            <JerseyIcon fill={theme.palette.light[50]} />
          </NavLink>
          <Link to={ROUTE.PROFILE}>
            <AvatarImg src={authStore.profile.avatar} alt="User avatar" />
          </Link>
        </NavLinks>
      )}
    </Wrapper>
  );
};

export default Navbar;
