import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface INavLinkProps {
  to: string;
  children: ReactNode;
  badge?: boolean;
}

const Wrapper = styled(Link)`
  position: relative;
  min-width: 32px;
  height: 32px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 5px;
  background-color: ${(props) => props.theme.palette.primary[500]};
  cursor: pointer;
`;

const Badge = styled.div`
  position: absolute;
  top: -3px;
  right: -3px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  border: 2px solid ${(props) => props.theme.palette.primary[700]};
  background-color: ${(props) => props.theme.palette.secondary[400]};
`;

const NavLink: React.FC<INavLinkProps> = ({ to, children, badge }) => {
  return (
    <Wrapper to={to}>
      {badge && <Badge />}
      {children}
    </Wrapper>
  );
};

export default NavLink;
