import Button from 'components/Button';
import Footer from 'components/Footer';
import Header from 'components/Header';
import Navbar from 'components/Nav/Navbar';
import PlayerDetails from 'components/PlayerDetails';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useStores from 'stores/helpers/useStores';
import { removeAccessToken } from 'utils/accessToken';
import { ROUTE } from 'utils/routes';

const Profile: React.FC = () => {
  const navigate = useNavigate();
  const { rootStore, playerStore, authStore } = useStores();

  const [player, setPlayer] = useState(
    playerStore.players?.find((p) => p.id === authStore.profile?.playerId)
  );

  useEffect(() => {
    if (
      !player &&
      !playerStore.players?.length &&
      authStore.profile?.playerId
    ) {
      playerStore.loadPlayer(authStore.profile.playerId, setPlayer);
    }
  }, [player, playerStore.players, authStore.profile?.playerId]);

  const onLogOut = () => {
    removeAccessToken();
    rootStore.reset();
    navigate(ROUTE.LOGIN);
  };

  return (
    <>
      <Navbar />

      <Header back compact title="Profile" />

      {player && <PlayerDetails player={player} />}

      <Footer noBorder>
        <Button variant="outline" width="l" onClick={onLogOut}>
          Log out
        </Button>
      </Footer>
    </>
  );
};

export default Profile;
