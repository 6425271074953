import Container from 'components/Container';
import PlayerScoreItem from 'components/PlayerScore/PlayerScoreItem';
import Text from 'components/Text';
import { PlayerListType } from 'enums/PlayerListType';
import { observer } from 'mobx-react-lite';
import React from 'react';
import styled, { useTheme } from 'styled-components';
import { IPlayer } from 'types/Player';

const ListWrapper = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.palette.dark[50]};
`;

const ColumnContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
`;

const ScoreColumn = styled(Text)`
  width: 130px;
  padding-left: 24px;
`;

interface IPlayerScoreList {
  players: IPlayer[];
  title?: string;
  type: PlayerListType;
}

const PlayerScoreList: React.FC<IPlayerScoreList> = ({
  players,
  title,
  type,
}) => {
  const theme = useTheme();

  if (!players.length) {
    return null;
  }

  return (
    <>
      {title && (
        <Container pt={32} pb={24}>
          <Text variant="h5" color={theme.palette.dark[800]}>
            {title}
          </Text>
        </Container>
      )}

      {type === PlayerListType.Main && (
        <ColumnContainer pb={12}>
          <Text
            variant="headline3"
            textTransform="uppercase"
            color={theme.palette.dark[200]}
          >
            Player
          </Text>

          <ScoreColumn
            variant="headline3"
            textTransform="uppercase"
            color={theme.palette.dark[200]}
          >
            Score
          </ScoreColumn>
        </ColumnContainer>
      )}

      <ListWrapper>
        {players.map((player, index) => (
          <PlayerScoreItem
            key={player.id}
            player={player}
            position={index + 1}
            type={type}
          />
        ))}
      </ListWrapper>
    </>
  );
};

export default observer(PlayerScoreList);
