import Text from 'components/Text';
import React from 'react';
import styled, { useTheme } from 'styled-components';

const StyledTab = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 0 20px;
`;

const StyledIcon = styled(({ component, ...props }) =>
  React.cloneElement(component, props)
)`
  margin-bottom: 8px;
`;

interface IProps {
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  title: string;
  onClick: () => void;
  isActive: boolean;
}

const FooterNavButton: React.FC<IProps> = ({
  Icon,
  title,
  onClick,
  isActive,
}) => {
  const theme = useTheme();

  const color = isActive
    ? theme.palette.primary[500]
    : theme.palette.primary[100];

  return (
    <StyledTab onClick={onClick}>
      <StyledIcon component={<Icon />} fill={color} />
      <Text variant="headline3" color={color} textTransform="uppercase">
        {title}
      </Text>
    </StyledTab>
  );
};

export default FooterNavButton;
