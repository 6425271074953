import { auth } from 'api/user';
import AppLoader from 'components/AppLoader';
import Button from 'components/Button';
import Container from 'components/Container';
import FormikInput from 'components/FormikInput';
import Header from 'components/Header';
import Navbar from 'components/Nav/Navbar';
import { Formik } from 'formik';
import useAlert from 'hooks/useAlert';
import useSlackLogin from 'hooks/useSlackLogin';
import useVerifyEmail from 'hooks/useVerifyEmail';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { setAccessToken } from 'utils/accessToken';
import { ROUTE } from 'utils/routes';

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  height: 100%;
`;

const StyledButton = styled(Button)`
  margin-top: 8px;
  margin-left: auto;
`;

const StyledForm = styled.form`
  flex: 1;
`;

const FooterButton = styled(Button)`
  margin: auto;
  margin-bottom: 32px;
`;

interface FormValues {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const navigate = useNavigate();
  const alert = useAlert();

  const slackLoading = useSlackLogin();
  const verifyLoading = useVerifyEmail();
  const [loginLoading, setLoginLoading] = useState(false);

  const loading = slackLoading || verifyLoading || loginLoading;

  const onSubmit = async (values: FormValues) => {
    if (!values.email || !values.password) {
      return;
    }

    setLoginLoading(true);

    try {
      const res = await auth({ auth: { type: 'password', ...values } });
      setAccessToken(res.token);
      navigate(ROUTE.HOME);
    } catch (e) {
      alert.error('Login failed. Check your info');
      setLoginLoading(false);
    }
  };

  const onForgotPassword = () => {
    navigate(ROUTE.FORGOT_PASSWORD);
  };

  return (
    <>
      <Navbar />

      <Header noBorder title="Login" />

      <Formik initialValues={{ email: '', password: '' }} onSubmit={onSubmit}>
        {({ handleSubmit, dirty }) => (
          <StyledForm onSubmit={handleSubmit}>
            <StyledContainer>
              <FormikInput
                name="email"
                type="email"
                label="Email"
                placeholder="Enter your email"
              />
              <FormikInput
                name="password"
                type="password"
                label="Password"
                placeholder="Enter your password"
              />

              <StyledButton
                onClick={onForgotPassword}
                variant="link"
                width="fit-content"
              >
                Forgot password
              </StyledButton>

              <FooterButton type="submit" disabled={!dirty} width="m">
                Log in
              </FooterButton>
            </StyledContainer>
          </StyledForm>
        )}
      </Formik>

      {loading && <AppLoader />}
    </>
  );
};

export default Login;
