import AppLoader from 'components/AppLoader';
import Button from 'components/Button';
import { Card, CardBody, CardFooter } from 'components/Card';
import Container from 'components/Container';
import MatchList from 'components/Match/MatchList';
import FooterNav from 'components/Nav/FooterNav';
import Navbar from 'components/Nav/Navbar';
import Text from 'components/Text';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useStores from 'stores/helpers/useStores';
import styled, { useTheme } from 'styled-components';
import { ROUTE } from 'utils/routes';

const Wrapper = styled.div`
  flex: 1;
  background-color: ${(props) => props.theme.palette.light[100]};
`;

const Games: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { matchStore, authStore } = useStores();

  useEffect(() => {
    if (!matchStore.matches.length) {
      matchStore.loadMatches();
    }
  }, []);

  const renderContent = () => {
    if (authStore.profile?.admin && !matchStore.matches.length) {
      return (
        <Card>
          <CardBody>
            <Text variant="h6">Play a Match</Text>
            <Text variant="p2">Select the players and create teams</Text>
          </CardBody>
          <CardFooter>
            <Button
              onClick={() => {
                navigate(ROUTE.PLAY_A_MATCH);
              }}
              width="m"
            >
              Play
            </Button>
          </CardFooter>
        </Card>
      );
    }

    return (
      <>
        <Container>
          <Text variant="h5" mt={32} mb={24} color={theme.palette.dark[800]}>
            Previous games
          </Text>
        </Container>

        {matchStore.matches.length > 0 ? (
          <MatchList />
        ) : (
          <Container>
            <Text variant="p1" color={theme.palette.dark[400]}>
              No games have been played
            </Text>
          </Container>
        )}
      </>
    );
  };

  return (
    <Wrapper>
      <Navbar />

      {renderContent()}

      <FooterNav />

      {matchStore.loading && !matchStore.matches.length && <AppLoader />}
    </Wrapper>
  );
};

export default observer(Games);
