import React, { ReactNode } from 'react';
import styled, { css, DefaultTheme } from 'styled-components';

type ButtonWidth = 'xs' | 's' | 'm' | 'l' | 'auto' | 'fit-content';
type ButtonSize = 'small' | 'normal';
type ButtonColor = 'primary' | 'secondary';
type ButtonVariant = 'solid' | 'outline' | 'link';

interface IStyledButtonProps {
  color?: ButtonColor;
  width?: ButtonWidth;
  size?: ButtonSize;
  variant?: ButtonVariant;
}

const getColorStyles = (
  theme: DefaultTheme,
  color: ButtonColor = 'primary',
  variant: ButtonVariant = 'solid'
) => {
  const colors = {
    primary: {
      main: theme.palette.primary[700],
      accent: theme.palette.light[50],
      hover: theme.palette.primary[500],
      disabled: theme.palette.primary[200],
      active: theme.palette.primary[900],
    },
    secondary: {
      main: theme.palette.secondary[400],
      accent: theme.palette.light[50],
      hover: theme.palette.secondary[400],
      disabled: theme.palette.secondary[200],
      active: theme.palette.secondary[600],
    },
  }[color];

  return {
    solid: css`
      color: ${colors.accent};
      background-color: ${colors.main};

      &:hover {
        background-color: ${colors.hover};
      }

      &:active {
        background-color: ${colors.active};
      }

      &:disabled {
        background-color: ${colors.disabled};
      }
    `,
    outline: css`
      color: ${colors.main};
      background-color: ${colors.accent};
      border: 1px solid ${colors.main};

      &:hover {
        background-color: ${theme.palette.light[100]};
        color: ${colors.hover};
        border-color: ${colors.hover};
      }

      &:active {
        background-color: ${theme.palette.dark[50]};
        color: ${colors.active};
        border-color: ${colors.active};
      }

      &:disabled {
        color: ${colors.disabled};
        border-color: ${colors.disabled};
      }
    `,
    link: css`
      color: ${colors.main};
      background-color: transparent;

      &:hover {
        color: ${colors.hover};
      }

      &:active {
        color: ${colors.active};
      }

      &:disabled {
        color: ${colors.disabled};
      }
    `,
  }[variant];
};

const getSizeStyles = (
  width: ButtonWidth = 's',
  size: ButtonSize = 'normal'
) => {
  const paddingVertical = size === 'small' ? '6px' : '12px';

  return {
    xs: css`
      padding: ${paddingVertical} 6px;
      width: 112px;
    `,
    s: css`
      padding: ${paddingVertical} 16px;
      width: 168px;
    `,
    m: css`
      padding: ${paddingVertical} 16px;
      width: 280px;
    `,
    l: css`
      padding: ${paddingVertical} 16px;
      width: 360px;
    `,
    auto: css`
      padding: ${paddingVertical} 16px;
    `,
    'fit-content': css`
      padding: 0;
    `,
  }[width];
};

const StyledButton = styled.button<IStyledButtonProps>`
  border: 0;
  border-radius: 100px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  font-size: ${(props) => props.theme.typography.button?.fontSize};
  font-weight: ${(props) => props.theme.typography.button?.fontWeight};
  line-height: ${(props) => props.theme.typography.button?.lineHeight};

  &:disabled {
    cursor: default;
  }

  ${({ theme, color, variant }) => getColorStyles(theme, color, variant)}
  ${({ width, size }) => getSizeStyles(width, size)}
`;

interface IButtonProps {
  children: ReactNode;
  onClick?: (() => void) | (() => Promise<void>);
  disabled?: boolean;
  width?: ButtonWidth;
  size?: ButtonSize;
  color?: ButtonColor;
  variant?: ButtonVariant;
  type?: 'button' | 'submit' | 'reset';
}

const Button: React.FC<IButtonProps> = ({
  children,
  onClick,
  disabled,
  type = 'button',
  ...rest
}) => {
  return (
    <StyledButton
      onClick={!disabled ? onClick : undefined}
      disabled={disabled}
      type={type}
      {...rest}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
