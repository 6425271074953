import Alert, { AlertVariant } from 'components/Alert';
import {
  AlertContext,
  AlertContextType,
} from 'contexts/AlertContext/AlertContext';
import React, { ReactNode, useState } from 'react';

interface IProps {
  children: ReactNode;
}

export interface IAlertOptions {
  autoHideDuration?: number | null;
  showClose?: boolean;
}

interface IState extends IAlertOptions {
  open: boolean;
  text: string;
  variant: AlertVariant;
}

const initialState: IState = {
  open: false,
  text: '',
  variant: 'info',
  autoHideDuration: 5000,
  showClose: true,
};

const AlertProvider: React.FC<IProps> = ({ children }) => {
  const [state, setState] = useState<IState>(initialState);

  const info = (text: string, options?: IAlertOptions) => {
    close();
    setState({ ...state, open: true, text, variant: 'info', ...options });
  };

  const success = (text: string, options?: IAlertOptions) => {
    close();
    setState({ ...state, open: true, text, variant: 'success', ...options });
  };

  const warning = (text: string, options?: IAlertOptions) => {
    close();
    setState({ ...state, open: true, text, variant: 'warning', ...options });
  };

  const error = (text: string, options?: IAlertOptions) => {
    close();
    setState({ ...state, open: true, text, variant: 'danger', ...options });
  };

  const close = () => setState(initialState);

  const context: AlertContextType = {
    info,
    success,
    warning,
    error,
    close,
    open: state.open,
  };

  return (
    <AlertContext.Provider value={context}>
      <Alert {...state} onClose={close} />
      {children}
    </AlertContext.Provider>
  );
};

export default AlertProvider;
