import jwtDecode, { JwtPayload } from 'jwt-decode';
import { DateTime } from 'luxon';

const tokenStorageKey = 'povio-token';

export const setAccessToken = (token: string) => {
  localStorage.setItem(tokenStorageKey, token);
};

export const getAccessToken = () => {
  return localStorage.getItem(tokenStorageKey);
};

export const removeAccessToken = () => {
  localStorage.removeItem(tokenStorageKey);
};

export const isAccessTokenValid = () => {
  const accessToken = getAccessToken();

  if (!accessToken) {
    return false;
  }

  try {
    const decoded = jwtDecode<JwtPayload>(accessToken);
    return !decoded.exp || DateTime.now().toSeconds() < decoded.exp;
  } catch (e) {}

  return false;
};
