import { createContext } from 'react';
import AuthStore from 'stores/AuthStore';
import MatchStore from 'stores/MatchStore';
import PlayerStore from 'stores/PlayerStore';
import RootStore from 'stores/RootStore';

export interface IStore {
  rootStore: RootStore;
  authStore: AuthStore;
  playerStore: PlayerStore;
  matchStore: MatchStore;
}

export const StoreContext = createContext<IStore>({} as IStore);
