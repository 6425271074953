import AppLoader from 'components/AppLoader';
import Button from 'components/Button';
import Footer from 'components/Footer';
import Header from 'components/Header';
import SummaryList from 'components/Summary/SummaryList';
import useAlert from 'hooks/useAlert';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useStores from 'stores/helpers/useStores';
import { ROUTE } from 'utils/routes';

const Summary: React.FC = () => {
  const navigate = useNavigate();
  const { playerStore } = useStores();
  const alert = useAlert();

  const onSubmit = async () => {
    playerStore.ratePlayers(
      () => {
        alert.success('Players rated successfully');
        navigate(ROUTE.HOME);
      },
      () => {
        alert.error('Failed to rate players');
      }
    );
  };

  return (
    <>
      <Header title="Summary" back compact />

      <SummaryList />

      <Footer>
        <Button onClick={onSubmit} width="m">
          Submit ratings
        </Button>
      </Footer>

      {playerStore.loading && <AppLoader />}
    </>
  );
};

export default observer(Summary);
