import { ReactComponent as ArrowRightIcon } from 'assets/arrow-right.svg';
import AppLoader from 'components/AppLoader';
import Button from 'components/Button';
import Footer from 'components/Footer';
import Header from 'components/Header';
import PlayMatchList from 'components/PlayMatch/PlayMatchList';
import TeamsList from 'components/Teams/TeamsList';
import useAlert from 'hooks/useAlert';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useStores from 'stores/helpers/useStores';
import styled from 'styled-components';
import { IMatch } from 'types/Match';
import { ROUTE } from 'utils/routes';

const ArrowRight = styled(ArrowRightIcon)`
  stroke: ${(props) => props.theme.palette.light[50]};
  margin-left: 8px;
`;

const TextContainer = styled.div`
  display: inline-flex;
  align-items: center;
`;

const ButtonContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const NormalText = styled.span`
  font-weight: 400;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

const PlayMatch: React.FC = () => {
  const { playerStore, matchStore } = useStores();
  const navigate = useNavigate();
  const alert = useAlert();

  const [match, setMatch] = useState<IMatch | null>(null);

  useEffect(() => {
    if (!playerStore.players.length) {
      playerStore.loadPlayers();
    }
    return () => {
      playerStore.setAllPlayersSelected(false);
    };
  }, []);

  const countSelected = () =>
    playerStore.players.filter((p) => p.selected).length;

  const canContinue = () => {
    return countSelected() >= 4;
  };

  const onCreateMatch = async () => {
    matchStore.createMatch(setMatch, () => {
      alert.error(
        'You have to add a result for your previous match before creating a new one'
      );
    });
  };

  const onReshuffle = async () => {
    if (!match) {
      return;
    }
    await matchStore.deleteMatch(match.id);
    await onCreateMatch();
  };

  const onConfirm = () => {
    if (!match) {
      return;
    }

    navigate(`${ROUTE.MATCH}/${match.id}`, { replace: true });
  };

  return (
    <>
      <Header
        title="Play a Match"
        description="Who's playing?"
        back
        compact={!!match}
        action={
          match ? null : (
            <Button
              variant="link"
              width="fit-content"
              onClick={() => playerStore.setAllPlayersSelected(true)}
            >
              Select all
            </Button>
          )
        }
      />

      {match ? (
        <TeamsList teams={match.teams} />
      ) : (
        <PlayMatchList
          players={playerStore.players}
          togglePlayer={(id) => playerStore.setPlayerSelected(id)}
        />
      )}

      <Footer>
        {match ? (
          <ButtonWrapper>
            <Button width="l" variant="outline" onClick={onReshuffle}>
              Reshuffle
            </Button>
            <Button width="l" onClick={onConfirm}>
              Start match
            </Button>
          </ButtonWrapper>
        ) : (
          <Button width="l" onClick={onCreateMatch} disabled={!canContinue()}>
            <ButtonContent>
              <div>
                {countSelected()} <NormalText>selected</NormalText>
              </div>
              <TextContainer>
                Continue <ArrowRight />
              </TextContainer>
            </ButtonContent>
          </Button>
        )}
      </Footer>

      {(playerStore.loading || matchStore.loading) && <AppLoader />}
    </>
  );
};

export default observer(PlayMatch);
