export enum ROUTE {
  HOME = '/',
  LOGIN = '/login',
  REGISTER = '/register',
  REGISTER_SUCCESS = '/register/success',
  FORGOT_PASSWORD = '/forgot-password',
  FORGOT_PASSWORD_SUCCESS = '/forgot-password/success',
  RESET_PASSWORD = '/reset-password',
  PROFILE = '/profile',
  RATE = '/rate',
  SUMMARY = '/summary',
  PLAYERS = '/players',
  UNRATED_PLAYERS = '/unrated-players',
  GAMES = '/games',
  PLAY_A_MATCH = '/new-match',
  MATCH = '/match',
}
