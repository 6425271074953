import styled from 'styled-components';

const Card = styled.div`
  padding: 32px 24px;
  background-color: ${(props) => props.theme.palette.light[50]};
`;

const CardBody = styled.div`
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 4px;
`;

const CardFooter = styled.div`
  display: flex;
  justify-content: center;
`;

export { Card, CardBody, CardFooter };
