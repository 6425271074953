import PlayMatchItem from 'components/PlayMatch/PlayMatchItem';
import React from 'react';
import styled from 'styled-components';
import { IPlayer } from 'types/Player';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 24px;
`;

interface IProps {
  players: IPlayer[];
  togglePlayer: (id: number) => void;
}

const PlayMatchList: React.FC<IProps> = ({ players, togglePlayer }) => {
  return (
    <Wrapper>
      {players.map((player) => (
        <PlayMatchItem
          key={player.id}
          player={player}
          onClick={() => togglePlayer(player.id)}
        />
      ))}
    </Wrapper>
  );
};

export default PlayMatchList;
