import { auth } from 'api/user';
import { SLACK_REDIRECT_URI } from 'config/Config';
import useQuery from 'hooks/useQuery';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { setAccessToken } from 'utils/accessToken';
import { ROUTE } from 'utils/routes';

const useSlackLogin = () => {
  const params = useQuery();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      const code = params.get('code');
      if (code) {
        setLoading(true);

        try {
          const res = await auth({
            auth: {
              code,
              provider: 'slack',
              type: 'oauth',
              redirectUri: SLACK_REDIRECT_URI,
            },
          });
          setAccessToken(res.token);
          navigate(ROUTE.HOME);
        } catch (e) {
          console.error('Login failed');
          console.error(e);
        }

        setLoading(false);
      }
    })();
  }, [params]);

  return loading;
};

export default useSlackLogin;
