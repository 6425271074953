import { ReactComponent as ArrowIcon } from 'assets/arrow-left.svg';
import Container from 'components/Container';
import Text from 'components/Text';
import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

const Wrapper = styled.div<{ noBorder?: boolean }>`
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: ${(props) => props.theme.palette.light[50]};
  border-bottom: ${(props) =>
    !props.noBorder && `1px solid ${props.theme.palette.dark[50]}`};

  display: flex;
  justify-content: center;
`;

const StyledContainer = styled(Container)<{ compact?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.compact ? 'center' : 'flex-start')};
  row-gap: 16px;
`;

const BackButton = styled.div<{ absolute?: boolean }>`
  cursor: pointer;
  display: inline-block;
  width: 24px;
  height: 24px;

  left: 24px;
  top: 50%;
  transform: ${(props) => props.absolute && 'translateY(-50%)'};
  position: ${(props) => props.absolute && 'absolute'};

  svg {
    width: 100%;
    height: 100%;
  }
`;

const Row = styled.div`
  display: flex;
  width: 100%;
`;

const ActionWrapper = styled.div`
  margin-left: auto;
`;

const AbsoluteActionWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);
`;

interface IHeaderProps {
  back?: boolean;
  title?: string;
  description?: string;
  compact?: boolean;
  action?: ReactNode;
  noBorder?: boolean;
}

const Header: React.FC<IHeaderProps> = ({
  back,
  title,
  description,
  compact,
  action,
  noBorder,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Wrapper noBorder={noBorder}>
      <StyledContainer compact={compact} py={24}>
        {back && (
          <BackButton onClick={() => navigate(-1)} absolute={compact}>
            <ArrowIcon stroke={theme.palette.primary[500]} />
          </BackButton>
        )}

        {title && (
          <Text variant={compact ? 'h6' : 'h4'} color={theme.palette.dark[800]}>
            {title}
          </Text>
        )}
        {!compact && (
          <Row>
            {description && <Text variant="p2">{description}</Text>}
            {action && <ActionWrapper>{action}</ActionWrapper>}
          </Row>
        )}

        {compact && action && (
          <AbsoluteActionWrapper>{action}</AbsoluteActionWrapper>
        )}
      </StyledContainer>
    </Wrapper>
  );
};

export default Header;
