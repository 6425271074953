import AppLoader from 'components/AppLoader';
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import useStores from 'stores/helpers/useStores';
import { isAccessTokenValid } from 'utils/accessToken';

interface IRequireAuthProps {
  redirectTo: string;
  children: React.ReactElement;
}

const RequireAuth: React.FC<IRequireAuthProps> = ({ children, redirectTo }) => {
  const [loading, setLoading] = useState(true);

  const { authStore } = useStores();

  useEffect(() => {
    if (!authStore.profile && loading) {
      (async () => {
        await authStore.loadUserProfile();
        setLoading(false);
      })();
    }
  }, [authStore.profile]);

  if (loading) {
    return <AppLoader />;
  }

  const isAuthenticated = isAccessTokenValid() && authStore.profile?.id;
  return isAuthenticated ? children : <Navigate to={redirectTo} />;
};

export default RequireAuth;
