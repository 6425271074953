import { apiRequest } from 'api/axios';

export interface AuthSlackInput {
  auth: {
    code: string;
    provider: string;
    type: 'oauth';
    redirectUri: string;
  };
}

export interface AuthPasswordInput {
  auth: {
    type: 'password';
    email: string;
    password: string;
  };
}

export type AuthInput = AuthSlackInput | AuthPasswordInput;

export interface AuthResponse {
  token: string;
}

export interface ProfileResponse {
  id: number;
  playerId: number;
  email: string;
  firstName: string;
  lastName: string;
  avatar?: string;
  admin?: boolean;
}

export interface RegisterInput {
  register: {
    email: string;
    password: string;
  };
}

export interface VerifyEmailInput {
  verifyEmail: {
    email: string;
    verificationToken: string;
  };
}

export interface RequestResetPasswordInput {
  resetPassword: {
    email: string;
  };
}

export interface ResetPasswordInput {
  resetPassword: {
    password: string;
    resetToken: string;
  };
}

export const auth = (data: AuthInput) => {
  return apiRequest<AuthInput, AuthResponse>('post', '/auth', { data });
};

export const getMe = () => {
  return apiRequest<undefined, ProfileResponse>('get', '/me');
};

export const register = (data: RegisterInput) => {
  return apiRequest<RegisterInput, undefined>('post', '/registration', {
    data,
  });
};

export const verifyEmail = (data: VerifyEmailInput) => {
  return apiRequest<VerifyEmailInput, undefined>(
    'post',
    '/email-verification',
    { data }
  );
};

export const requestResetPassword = (data: RequestResetPasswordInput) => {
  return apiRequest<RequestResetPasswordInput, undefined>(
    'post',
    '/reset-password',
    { data }
  );
};

export const resetPassword = (data: ResetPasswordInput) => {
  return apiRequest<ResetPasswordInput, undefined>('patch', '/reset-password', {
    data,
  });
};
