import { ReactComponent as CheckCircle } from 'assets/check-circle.svg';
import { ReactComponent as Close } from 'assets/close.svg';
import { ReactComponent as Info } from 'assets/info2.svg';
import { ReactComponent as Warning } from 'assets/warning.svg';
import Text from 'components/Text';
import React, { useEffect } from 'react';
import styled, { DefaultTheme, useTheme } from 'styled-components';

export type AlertVariant = 'info' | 'success' | 'warning' | 'danger';

const getColor = (theme: DefaultTheme, variant: AlertVariant) => {
  return {
    info: theme.palette.primary[500],
    success: theme.palette.success[400],
    warning: theme.palette.warning[400],
    danger: theme.palette.danger[400],
  }[variant];
};

const getIcon = (variant: AlertVariant) => {
  return {
    info: Info,
    success: CheckCircle,
    warning: Warning,
    danger: Warning,
  }[variant];
};

const AlertContainer = styled.div`
  z-index: 10;
  position: fixed;
  bottom: 112px;
  left: 50%;
  transform: translateX(-50%);
`;

const Wrapper = styled.div<{ variant: AlertVariant; showClose?: boolean }>`
  padding: 20px ${({ showClose }) => (showClose ? '56px' : '16px')} 20px 64px;
  width: 330px;
  position: relative;

  background-color: ${({ theme, variant }) => getColor(theme, variant)};
  border-radius: 4px;
`;

const StyledIcon = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
`;

const StyledClose = styled(Close)`
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
`;

interface IAlertProps {
  variant: AlertVariant;
  text: string;
  showClose?: boolean;
  open: boolean;
  onClose: () => void;
  autoHideDuration?: number | null;
}

const Alert: React.FC<IAlertProps> = ({
  text,
  variant,
  showClose,
  open,
  onClose,
  autoHideDuration,
}) => {
  const theme = useTheme();

  useEffect(() => {
    if (open && autoHideDuration) {
      const timeout = setTimeout(onClose, autoHideDuration);
      return () => clearTimeout(timeout);
    }
  }, [open]);

  if (!open) {
    return null;
  }

  return (
    <AlertContainer>
      <Wrapper variant={variant} showClose={showClose}>
        <StyledIcon as={getIcon(variant)} />

        <Text variant="notification" color={theme.palette.light[50]}>
          {text}
        </Text>

        {showClose && <StyledClose onClick={onClose} />}
      </Wrapper>
    </AlertContainer>
  );
};

export default Alert;
