import Text from 'components/Text';
import ToggleButton from 'components/ToggleButton';
import React from 'react';
import styled, { useTheme } from 'styled-components';
import { IPlayer } from 'types/Player';

const StyledButton = styled(ToggleButton)`
  width: 100%;
  max-width: 360px;
  padding: 8px 16px;
  margin-bottom: 16px;
  text-align: left;
`;

interface IProps {
  player: IPlayer;
  onClick: () => void;
}

const PlayMatchItem: React.FC<IProps> = ({ player, onClick }) => {
  const theme = useTheme();

  return (
    <StyledButton selected={player.selected} onClick={onClick}>
      <Text
        variant="headline1"
        color={
          player.selected ? theme.palette.primary[500] : theme.palette.dark[200]
        }
        weight={player.selected ? 600 : 400}
        textTransform="uppercase"
      >
        {player.firstName} {player.lastName}
      </Text>
    </StyledButton>
  );
};

export default PlayMatchItem;
