import { apiRequest } from 'api/axios';
import { IPlayerRating } from 'types/PlayerRating';

export interface RatePlayersInput {
  ratings: IPlayerRating[];
}

export const ratePlayers = (data: RatePlayersInput) => {
  return apiRequest<RatePlayersInput, undefined>('post', '/ratings', { data });
};
