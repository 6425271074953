import { register } from 'api/user';
import Button from 'components/Button';
import Container from 'components/Container';
import Footer from 'components/Footer';
import FormikInput from 'components/FormikInput';
import Header from 'components/Header';
import Navbar from 'components/Nav/Navbar';
import Spinner from 'components/Spinner';
import Text from 'components/Text';
import { Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useStores from 'stores/helpers/useStores';
import styled from 'styled-components';
import { ROUTE } from 'utils/routes';
import * as Yup from 'yup';

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

const LoadingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 32px;

  background-color: ${(props) => props.theme.palette.light[50]};
`;

interface FormValues {
  email: string;
  password: string;
  passwordConfirm: string;
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Must be a valid email address')
    .required('Email is required'),
  password: Yup.string()
    .min(8, 'Password must include at least 8 characters')
    .required('Password is required'),
  passwordConfirm: Yup.string()
    .required('Confirm password is required')
    .when('password', {
      is: (value: string) => {
        return value && value.length > 0;
      },
      then: Yup.string().oneOf(
        [Yup.ref('password'), undefined],
        'Passwords do not match'
      ),
    }),
});

const Register: React.FC = () => {
  const { authStore } = useStores();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const onSubmit = async (
    values: FormValues,
    helpers: FormikHelpers<FormValues>
  ) => {
    authStore.setEmail(values.email);
    setLoading(true);
    try {
      await register({
        register: { email: values.email, password: values.password },
      });
      navigate(ROUTE.REGISTER_SUCCESS);
    } catch (e) {
      setLoading(false);
      helpers.setErrors({ email: 'Email is incorrect' });
    }
  };

  return (
    <>
      <Navbar />

      <Header
        title="Create an account"
        description="Provide your email and create a new password. Enter the same email that you use to log into Povio Fuzbal space on Slack."
        noBorder
      />

      <Formik
        initialValues={{ email: '', password: '', passwordConfirm: '' }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isValid, dirty }) => (
          <form onSubmit={handleSubmit}>
            <StyledContainer>
              <FormikInput
                name="email"
                label="Email"
                type="email"
                placeholder="Enter your email"
              />

              <FormikInput
                name="password"
                label="New password"
                type="password"
                placeholder="Enter your desired password"
                helpText="Password must include at least 8 characters"
              />
              <FormikInput
                name="passwordConfirm"
                label="Confirm password"
                type="password"
                placeholder="Confirm your password"
              />
            </StyledContainer>

            <Footer noBorder>
              <Button type="submit" width="m" disabled={!dirty || !isValid}>
                Create account
              </Button>
            </Footer>
          </form>
        )}
      </Formik>

      {loading && (
        <LoadingContainer>
          <Spinner size={42} thickness={4} />
          <Text variant="h5">Creating account</Text>
        </LoadingContainer>
      )}
    </>
  );
};

export default Register;
