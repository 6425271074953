import { ReactComponent as ChevronRightIcon } from 'assets/chevron-right.svg';
import Container from 'components/Container';
import Text from 'components/Text';
import React from 'react';
import { Link } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

const Wrapper = styled(Link)`
  display: flex;
  justify-content: center;
  text-decoration: none;
  color: ${(props) => props.theme.palette.light[50]};
  background-color: ${(props) => props.theme.palette.primary[500]};
`;

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  column-gap: 16px;
`;

const TextWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

interface IBannerProps {
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  title: string;
  description: string;
  link: string;
}

const Banner: React.FC<IBannerProps> = ({ Icon, title, description, link }) => {
  const theme = useTheme();

  return (
    <Wrapper to={link}>
      <StyledContainer py={24}>
        <Icon />
        <TextWrapper>
          <Text variant="h7">{title}</Text>
          <Text variant="h7" weight={400}>
            {description}
          </Text>
        </TextWrapper>
        <ChevronRightIcon stroke={theme.palette.light[50]} />
      </StyledContainer>
    </Wrapper>
  );
};

export default Banner;
