import { apiRequest } from 'api/axios';
import { IPlayer } from 'types/Player';

export const getPlayers = () => {
  return apiRequest<undefined, IPlayer[]>('get', '/players');
};

export const getPlayer = (id: number) => {
  return apiRequest<undefined, IPlayer>('get', `/players/${id}`);
};
