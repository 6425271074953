import styled from 'styled-components';

interface IContainerProps {
  py?: number;
  pt?: number;
  pb?: number;
}

const Container = styled.div<IContainerProps>`
  width: 100%;
  max-width: ${(props) => props.theme.contentMaxWidth};

  padding-left: 24px;
  padding-right: 24px;
  padding-top: ${({ py, pt }) => (py || pt) && `${py || pt}px`};
  padding-bottom: ${({ py, pb }) => (py || pb) && `${py || pb}px`};
  margin-left: auto;
  margin-right: auto;
`;

export default Container;
