import { requestResetPassword } from 'api/user';
import AppLoader from 'components/AppLoader';
import Button from 'components/Button';
import Container from 'components/Container';
import Footer from 'components/Footer';
import FormikInput from 'components/FormikInput';
import Header from 'components/Header';
import Navbar from 'components/Nav/Navbar';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useStores from 'stores/helpers/useStores';
import styled from 'styled-components';
import { ROUTE } from 'utils/routes';
import * as Yup from 'yup';

const StyledContainer = styled(Container)`
  display: flex;
`;

interface FormValues {
  email: string;
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Must be a valid email address')
    .required('Email is required'),
});

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const { authStore } = useStores();

  const [loading, setLoading] = useState(false);

  const onSubmit = async (values: FormValues) => {
    setLoading(true);
    authStore.setEmail(values.email);
    try {
      await requestResetPassword({ resetPassword: { email: values.email } });
    } catch (e) {}
    setLoading(false);
    navigate(ROUTE.FORGOT_PASSWORD_SUCCESS);
  };

  return (
    <>
      <Navbar />
      <Header
        back
        noBorder
        title="Forgot password?"
        description="Enter your email to request a password reset."
      />

      <Formik
        initialValues={{ email: '' }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isValid, dirty }) => (
          <form onSubmit={handleSubmit}>
            <StyledContainer>
              <FormikInput
                name="email"
                label="Email"
                type="email"
                placeholder="Enter your email"
              />
            </StyledContainer>

            <Footer noBorder>
              <Button type="submit" width="m" disabled={!dirty || !isValid}>
                Request password reset
              </Button>
            </Footer>
          </form>
        )}
      </Formik>

      {loading && <AppLoader />}
    </>
  );
};

export default ForgotPassword;
