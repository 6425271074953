import { getPlayer, getPlayers } from 'api/player';
import { ratePlayers } from 'api/rate';
import { makeAutoObservable } from 'mobx';
import RootStore from 'stores/RootStore';
import { IPlayer } from 'types/Player';
import { IPlayerRating } from 'types/PlayerRating';

export default class PlayerStore {
  rootStore: RootStore;
  loading = false;
  players: IPlayer[] = [];
  ratedPlayers: IPlayer[] = [];
  unratedPlayers: IPlayer[] = [];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  reset() {
    this.loading = false;
    this.players = [];
    this.ratedPlayers = [];
    this.unratedPlayers = [];
  }

  setPlayerScore(playerId: number, rating: number) {
    const player = this.unratedPlayers.find((p) => p.id === playerId);
    if (player) {
      player.localRating = rating;
      this.unratedPlayers = [...this.unratedPlayers];
    }
  }

  setPlayerSelected(playerId: number, selected?: boolean) {
    const player = this.players.find((p) => p.id === playerId);
    if (player) {
      player.selected = selected ?? !player.selected;
      this.players = [...this.players];
    }
  }

  setAllPlayersSelected(selected: boolean) {
    this.players.forEach((player) => {
      player.selected = selected;
    });
    this.players = [...this.players];
  }

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  setPlayers(players: IPlayer[]) {
    this.players = players;
  }

  setRatedPlayers(players: IPlayer[]) {
    this.ratedPlayers = players;
  }

  setUnratedPlayers(players: IPlayer[]) {
    this.unratedPlayers = players;
  }

  async loadPlayers() {
    const { authStore } = this.rootStore;
    const userId = authStore.profile?.playerId;
    this.setLoading(true);

    try {
      const players = await getPlayers();
      const ratedPlayers = players.filter((p) => !!p.userRating);
      const unratedPlayers = players.filter(
        (p) => !p.userRating && p.id !== userId
      );

      this.setPlayers(players);
      this.setRatedPlayers(ratedPlayers);
      this.setUnratedPlayers(unratedPlayers);
    } catch (e) {
      this.setPlayers([]);
      this.setRatedPlayers([]);
      this.setUnratedPlayers([]);
    } finally {
      this.setLoading(false);
    }
  }

  async ratePlayers(
    onSuccess?: () => void,
    onError?: (error: unknown) => void
  ) {
    const ratings = this.unratedPlayers
      .filter((p) => !!p.localRating)
      .map(
        (p) =>
          ({
            playerId: p.id,
            rating: p.localRating,
          } as IPlayerRating)
      );

    if (!ratings.length) {
      return;
    }

    this.setLoading(true);
    try {
      await ratePlayers({ ratings });
      onSuccess?.();
      this.loadPlayers();
    } catch (e) {
      onError?.(e);
      this.setLoading(false);
    }
  }

  async loadPlayer(
    playerId: number,
    onSuccess?: (player: IPlayer) => void,
    onError?: (error: unknown) => void
  ) {
    try {
      const player = await getPlayer(playerId);
      onSuccess?.(player);
    } catch (e) {
      onError?.(e);
    }
  }
}
