import AlertProvider from 'contexts/AlertContext/AlertProvider';
import Pages from 'pages/Pages';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import StoreProvider from 'stores/helpers/StoreProvider';
import { ThemeProvider } from 'styled-components';
import { Theme } from 'theme/theme';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <StoreProvider>
        <ThemeProvider theme={Theme}>
          <AlertProvider>
            <Pages />
          </AlertProvider>
        </ThemeProvider>
      </StoreProvider>
    </BrowserRouter>
  );
};

export default App;
