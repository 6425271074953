import { FontFamily } from 'enums/FontFamily';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { ITypography } from 'theme/types';

const StyledText = styled.div<Omit<ITextProps, 'children'>>((props) => ({
  ...props.theme.typography[props.variant],
  ...(props.weight && { fontWeight: props.weight }),
  ...(props.family && { fontFamily: props.family }),
  ...(props.size && { fontSize: props.size }),
  color: props.color,
  textAlign: props.align,
  textTransform: props.textTransform,
  marginTop: (props.my || props.mt) ?? 0,
  marginBottom: (props.my || props.mb) ?? 0,
}));

const typographyTagMap: Partial<
  Record<keyof ITypography, keyof JSX.IntrinsicElements>
> = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  h7: 'h6',
  h8: 'h6',
  p1: 'p',
  p2: 'p',
  inputLabel: 'label',
};

interface ITextProps {
  children: ReactNode;
  className?: string;
  variant: keyof ITypography;
  weight?: number | string;
  family?: FontFamily;
  size?: number;
  color?: string;
  align?: 'center' | 'left' | 'right';
  textTransform?: 'capitalize' | 'uppercase' | 'lowercase';
  my?: number;
  mt?: number;
  mb?: number;
}

type IVariantProps =
  | { variant: keyof Omit<ITypography, 'inputLabel'>; htmlFor?: never }
  | { variant: 'inputLabel'; htmlFor?: string };

type IProps = ITextProps & IVariantProps;

const Text: React.FC<IProps> = ({ children, ...rest }) => {
  return (
    <StyledText as={typographyTagMap[rest.variant]} {...rest}>
      {children}
    </StyledText>
  );
};

export default Text;
