import AppLoader from 'components/AppLoader';
import Header from 'components/Header';
import PlayerDetails from 'components/PlayerDetails';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useStores from 'stores/helpers/useStores';
import styled from 'styled-components';
import { ROUTE } from 'utils/routes';

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

type IPlayerParams = {
  playerId: string;
};

const Player: React.FC = () => {
  const navigate = useNavigate();
  const { playerId } = useParams<IPlayerParams>();
  const { playerStore } = useStores();
  const [player, setPlayer] = useState(() => {
    const id = playerId ? parseInt(playerId, 10) : undefined;
    if (!id) {
      return undefined;
    }

    return playerStore.players.find((p) => p.id === id);
  });

  const goHome = useCallback(() => {
    navigate(ROUTE.HOME);
  }, [navigate]);

  useEffect(() => {
    const id = playerId ? parseInt(playerId, 10) : undefined;
    if (id && !player && !playerStore.players?.length) {
      playerStore.loadPlayer(id, setPlayer, goHome);
    } else if (!id || (!player && !!playerStore.players?.length)) {
      goHome();
    }
  }, [playerId, player, playerStore.players, setPlayer, goHome]);

  return (
    <Wrapper>
      {player ? (
        <>
          <Header
            back
            compact
            title={`${player.firstName} ${player.lastName}`}
          />

          <PlayerDetails player={player} />
        </>
      ) : (
        <AppLoader />
      )}
    </Wrapper>
  );
};

export default observer(Player);
