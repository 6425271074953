import Button from 'components/Button';
import Container from 'components/Container';
import Footer from 'components/Footer';
import Header from 'components/Header';
import PlayerScoreList from 'components/PlayerScore/PlayerScoreList';
import Text from 'components/Text';
import { PlayerListType } from 'enums/PlayerListType';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useStores from 'stores/helpers/useStores';
import styled, { useTheme } from 'styled-components';
import { ROUTE } from 'utils/routes';

const NoResult = styled.div`
  border-top: 1px solid ${(props) => props.theme.palette.dark[50]};
`;

const UnratedPlayers: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { playerStore } = useStores();

  useEffect(() => {
    if (!playerStore.players.length) {
      playerStore.loadPlayers();
    }
  }, []);

  const onRateAll = () => {
    navigate(ROUTE.RATE);
  };

  return (
    <>
      <Header back noBorder title="Unrated players" />

      <Container pb={12}>
        <Text
          variant="headline3"
          color={theme.palette.dark[200]}
          textTransform="uppercase"
        >
          Player
        </Text>
      </Container>

      {playerStore.unratedPlayers.length > 0 ? (
        <>
          <PlayerScoreList
            players={playerStore.unratedPlayers}
            type={PlayerListType.Unrated}
          />
          <Footer>
            <Button width="m" onClick={onRateAll}>
              Rate all
            </Button>
          </Footer>
        </>
      ) : (
        <NoResult>
          <Container pt={24}>
            <Text variant="p1" color={theme.palette.dark[400]}>
              You have no unrated players at the moment.
            </Text>
          </Container>
        </NoResult>
      )}
    </>
  );
};

export default observer(UnratedPlayers);
