import {
  createMatch,
  deleteMatch,
  finishMatch,
  getMatch,
  getMatches,
} from 'api/match';
import { DateTime } from 'luxon';
import { makeAutoObservable } from 'mobx';
import RootStore from 'stores/RootStore';
import { IMatch } from 'types/Match';

export default class MatchStore {
  rootStore: RootStore;
  loading = false;
  matches: IMatch[] = [];

  offset = 0;
  totalCount: number | undefined;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  reset() {
    this.loading = false;
    this.matches = [];
    this.offset = 0;
    this.totalCount = undefined;
  }

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  setMatches(matches: IMatch[]) {
    this.matches = matches;
  }

  setMatch(match: IMatch) {
    const index = this.matches.findIndex((m) => m.id === match.id);
    if (index > -1) {
      this.matches[index] = match;
    } else {
      this.matches.unshift(...this.processMatches(match));
    }

    this.setMatches([...this.matches]);
  }

  async deleteMatch(
    id: number,
    onSuccess?: () => void,
    onError?: (err: unknown) => void
  ) {
    try {
      this.setLoading(true);
      await deleteMatch(id);

      this.setMatches(this.matches.filter((m) => m.id !== id));
      this.setOffset(this.offset - 1);
      if (this.totalCount !== undefined) {
        this.setTotalCount(this.totalCount - 1);
      }

      onSuccess?.();
    } catch (e) {
      onError?.(e);
    } finally {
      this.setLoading(false);
    }
  }

  setOffset(offset: number) {
    this.offset = offset;
  }

  setTotalCount(count: number | undefined) {
    this.totalCount = count;
  }

  async loadMatches() {
    this.setLoading(true);

    try {
      const res = await getMatches(this.offset);
      this.setMatches([
        ...this.matches,
        ...this.processMatches(...res.matches),
      ]);
      this.setOffset(res.nextOffset);
      this.setTotalCount(res.totalCount);
    } catch (e) {
      this.setMatches([]);
    } finally {
      this.setLoading(false);
    }
  }

  processMatches(...matches: IMatch[]) {
    const userId = this.rootStore.authStore.profile?.playerId;

    for (const match of matches) {
      match.monthYear = DateTime.fromFormat(match.date, 'yyyy-MM-dd').toFormat(
        'LLLL yyyy'
      );
      match.isWinner =
        match.played &&
        !!match.teams
          .find((team) => team.won)
          ?.players.find((player) => player.id === userId);
      match.isLoser =
        match.played &&
        !!match.teams
          .filter((team) => !team.won)
          .find(
            (team) => !!team.players.find((player) => player.id === userId)
          );
    }

    return matches;
  }

  async loadMatch(id: number) {
    this.setLoading(true);

    try {
      const match = await getMatch(id);
      this.setMatch(match);
    } catch (e) {
    } finally {
      this.setLoading(false);
    }
  }

  async createMatch(
    onSuccess?: (match: IMatch) => void,
    onError?: (error: unknown) => void
  ) {
    this.setLoading(true);
    try {
      const match = await createMatch({
        match: {
          noOfTeams: 2,
          playerIds: this.rootStore.playerStore.players
            .filter((p) => p.selected)
            .map((p) => p.id),
          date: DateTime.local().toFormat('yyyy-MM-dd'),
        },
      });

      this.setMatch(match);
      this.setOffset(this.offset + 1);
      if (this.totalCount !== undefined) {
        this.setTotalCount(this.totalCount + 1);
      }

      onSuccess?.(match);
    } catch (e) {
      onError?.(e);
    } finally {
      this.setLoading(false);
    }
  }

  async finishMatch(
    match: IMatch,
    winningTeamId: number,
    onSuccess?: () => void,
    onError?: (err: unknown) => void
  ) {
    try {
      this.setLoading(true);
      await finishMatch(match.id, { match: { winningTeamId } });
      match.played = true;
      this.setMatch(match);
      onSuccess?.();
    } catch (e) {
      onError?.(e);
    } finally {
      this.setLoading(false);
    }
  }
}
