import styled from 'styled-components';

interface SpinnerProps {
  size?: number;
  thickness?: number;
  primaryColor?: string;
  secondaryColor?: string;
}

const Spinner = styled.div<SpinnerProps>`
  border: ${({ thickness, theme, secondaryColor }) =>
    `${thickness || 8}px solid ${
      secondaryColor || theme.palette.primary[100]
    }`};
  border-top: ${({ thickness, theme, primaryColor }) =>
    `${thickness || 8}px solid ${primaryColor || theme.palette.primary[500]}`};
  border-radius: 50%;
  width: ${(props) => props.size || 80}px;
  height: ${(props) => props.size || 80}px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default Spinner;
