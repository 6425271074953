import AppLoader from 'components/AppLoader';
import Button from 'components/Button';
import Container from 'components/Container';
import Footer from 'components/Footer';
import Header from 'components/Header';
import TeamsList from 'components/Teams/TeamsList';
import Text from 'components/Text';
import ToggleButton from 'components/ToggleButton';
import useAlert from 'hooks/useAlert';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useStores from 'stores/helpers/useStores';
import styled from 'styled-components';
import { ROUTE } from 'utils/routes';

const Banner = styled.div`
  display: flex;
  justify-content: center;
  color: ${(props) => props.theme.palette.light[50]};
  background-color: ${(props) => props.theme.palette.primary[500]};
`;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FooterFormWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  margin-bottom: 16px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 16px;
`;

const StyledToggleButton = styled(ToggleButton)`
  padding: 16px 12px;
  margin-top: 8px;
  flex: 1;
`;

type IMatchParams = {
  matchId: string;
};

const Match: React.FC = () => {
  const navigate = useNavigate();
  const { matchStore, authStore } = useStores();
  const { matchId } = useParams<IMatchParams>();
  const alert = useAlert();
  const [winningTeamId, setWinningTeamId] = useState<number | null>(null);

  const match = useMemo(() => {
    const id = matchId ? parseInt(matchId, 10) : undefined;
    if (!id) {
      return undefined;
    }

    return matchStore.matches.find((m) => m.id === id);
  }, [matchId, matchStore.matches]);
  const isNotPlayed = match?.teams && !match?.played;
  const isAdmin = !!authStore.profile?.admin;

  useEffect(() => {
    const id = matchId ? parseInt(matchId, 10) : undefined;
    if ((!match || !match.teams) && id) {
      matchStore.loadMatch(id);
    }

    return () => {
      if (matchStore.totalCount === undefined) {
        matchStore.setMatches([]);
      }
    };
  }, []);

  useEffect(() => {
    if (!match && !matchStore.loading) {
      navigate(ROUTE.GAMES);
    }
  }, [match, matchStore.loading]);

  const onRemove = async () => {
    if (!match) {
      return;
    }

    await matchStore.deleteMatch(
      match.id,
      () => {
        alert.success('Match removed successfully');
        navigate(ROUTE.GAMES);
      },
      () => {
        alert.error('Failed to remove the match');
      }
    );
  };

  const onFinishMatch = async () => {
    if (!match || winningTeamId === null) {
      return;
    }

    await matchStore.finishMatch(
      match,
      winningTeamId,
      () => {
        alert.success('Results submitted successfully');
        navigate(ROUTE.GAMES);
      },
      () => {
        alert.error('Failed to submit results');
      }
    );
  };

  return (
    <>
      <Header
        title={
          match
            ? DateTime.fromFormat(match.date, 'yyyy-MM-dd').toFormat(
                'd. M. yyyy'
              )
            : 'Match'
        }
        back
        compact
        action={
          isAdmin && !match?.played ? (
            <Button
              color="secondary"
              variant="link"
              width="fit-content"
              onClick={onRemove}
            >
              Remove
            </Button>
          ) : null
        }
      />

      {isNotPlayed && (
        <>
          {isAdmin ? (
            <Banner>
              <Container py={14}>
                <Text variant="p1">Which team won?</Text>
              </Container>
            </Banner>
          ) : (
            <Banner>
              <Container py={14}>
                <Text variant="p1">
                  Results will be available once the game host declares who won
                </Text>
              </Container>
            </Banner>
          )}
        </>
      )}

      {match?.teams && <TeamsList teams={match.teams} />}

      {isAdmin && isNotPlayed && (
        <Footer>
          <StyledContainer>
            <FooterFormWrapper>
              <Text variant="headline2" mb={8}>
                Select the winning team
              </Text>
              <ButtonContainer>
                {match.teams.map((team, index) => (
                  <StyledToggleButton
                    key={team.id}
                    onClick={() =>
                      setWinningTeamId(
                        winningTeamId !== team.id ? team.id : null
                      )
                    }
                    selected={winningTeamId === team.id}
                  >
                    <Text
                      variant="headline1"
                      textTransform="uppercase"
                      weight={winningTeamId === team.id ? 600 : 400}
                    >
                      Team {index + 1}
                    </Text>
                  </StyledToggleButton>
                ))}
              </ButtonContainer>
            </FooterFormWrapper>

            <Button
              width="m"
              onClick={onFinishMatch}
              disabled={winningTeamId === null}
            >
              Submit result
            </Button>
          </StyledContainer>
        </Footer>
      )}

      {matchStore.loading && <AppLoader />}
    </>
  );
};

export default observer(Match);
