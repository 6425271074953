import Container from 'components/Container';
import Text from 'components/Text';
import React, { useMemo } from 'react';
import useStores from 'stores/helpers/useStores';
import styled, { useTheme } from 'styled-components';
import { ITeam } from 'types/Team';

const StyledContainer = styled(Container)`
  display: flex;
`;

const TeamsHeader = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px 0;
  border-bottom: 1px solid ${(props) => props.theme.palette.dark[50]};
`;

const TeamTitle = styled(Text)`
  display: block;
  flex: 1;
  padding-left: 24px;

  &:first-child {
    padding-left: 0;
    padding-right: 24px;
  }
`;

const TeamsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
`;

const PlayerName = styled(Text)`
  padding: 12px 0;
`;

const TeamList = styled.div`
  padding: 12px 0;
  flex: 1;
  border-right: 1px solid ${(props) => props.theme.palette.dark[50]};

  &:last-child {
    border-right: 0;
  }

  &:not(:first-child) ${PlayerName} {
    padding-left: 24px;
  }
`;

interface ITeamsListProps {
  teams: ITeam[];
}

const TeamsList: React.FC<ITeamsListProps> = ({ teams }) => {
  const theme = useTheme();

  const { authStore } = useStores();

  const [winners, rest] = useMemo(() => {
    const win = teams.find((t) => t.won === true);
    return [win, teams.filter((t) => t.id !== win?.id)];
  }, [teams]);

  return (
    <>
      <TeamsHeader>
        <StyledContainer>
          {winners && (
            <TeamTitle
              variant="headline1"
              textTransform="uppercase"
              color={theme.palette.dark[200]}
            >
              Winners
            </TeamTitle>
          )}

          {rest.map((team, index) => (
            <TeamTitle
              key={team.id}
              variant="headline1"
              textTransform="uppercase"
              color={theme.palette.dark[200]}
            >
              {winners ? 'Losers' : `Team ${index + 1}`}
            </TeamTitle>
          ))}
        </StyledContainer>
      </TeamsHeader>

      <TeamsWrapper>
        <StyledContainer>
          {winners && (
            <TeamList>
              {winners.players.map((player) => (
                <PlayerName
                  key={player.id}
                  variant="headline2"
                  textTransform="uppercase"
                  color={theme.palette.dark[800]}
                  weight={authStore.profile?.playerId === player.id ? 600 : 400}
                >
                  {player.firstName} {player.lastName}
                </PlayerName>
              ))}
            </TeamList>
          )}

          {rest.map((team) => (
            <TeamList key={team.id}>
              {team.players.map((player) => (
                <PlayerName
                  key={player.id}
                  variant="headline2"
                  textTransform="uppercase"
                  color={theme.palette.dark[800]}
                  weight={authStore.profile?.playerId === player.id ? 600 : 400}
                >
                  {player.firstName} {player.lastName}
                </PlayerName>
              ))}
            </TeamList>
          ))}
        </StyledContainer>
      </TeamsWrapper>
    </>
  );
};

export default TeamsList;
