import Container from 'components/Container';
import Text from 'components/Text';
import React from 'react';
import styled from 'styled-components';
import { IPlayer } from 'types/Player';

const Wrapper = styled.div`
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.palette.dark[50]};
`;

const StyledContainer = styled(Container)`
  display: flex;
`;

const StyledName = styled(Text)`
  padding: 12px 24px 12px 0;
  flex: 1;
  border-right: 1px solid ${(props) => props.theme.palette.dark[50]};
`;

const StyledRating = styled(Text)`
  padding: 12px 0 12px 24px;
  min-width: 65px;
  text-align: center;
`;

interface ISummaryItemProps {
  index: number;
  player: IPlayer;
}

const SummaryItem: React.FC<ISummaryItemProps> = ({ index, player }) => {
  return (
    <Wrapper>
      <StyledContainer>
        <StyledName variant="h8" textTransform="uppercase">
          {index}. {player.firstName} {player.lastName}
        </StyledName>
        <StyledRating variant="h8">{player.localRating}</StyledRating>
      </StyledContainer>
    </Wrapper>
  );
};

export default SummaryItem;
