import Spinner from 'components/Spinner';
import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => rgba(props.theme.palette.dark[500], 0.6)};

  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
`;

const AppLoader: React.FC = () => {
  return (
    <Wrapper>
      <Spinner />
    </Wrapper>
  );
};

export default AppLoader;
