import { ReactComponent as BallIcon } from 'assets/ball.svg';
import { ReactComponent as PlayersIcon } from 'assets/players.svg';
import { ReactComponent as VsIcon } from 'assets/vs.svg';
import Button from 'components/Button';
import Container from 'components/Container';
import Footer from 'components/Footer';
import FooterNavButton from 'components/Nav/FooterNavButton';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useStores from 'stores/helpers/useStores';
import styled, { useTheme } from 'styled-components';
import { ROUTE } from 'utils/routes';

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-around;
`;

const StyledBallIcon = styled(BallIcon)`
  margin-right: 8px;
`;

const StyledButton = styled(Button)`
  padding-left: 12px;
  padding-right: 12px;
`;

const FooterNav: React.FC = () => {
  const { authStore } = useStores();
  const theme = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isAdmin = !!authStore.profile?.admin;

  return (
    <Footer>
      <StyledContainer>
        <FooterNavButton
          onClick={() => navigate(ROUTE.HOME)}
          title="Players"
          Icon={PlayersIcon}
          isActive={pathname === ROUTE.HOME}
        />

        {isAdmin && (
          <StyledButton
            onClick={() => navigate(ROUTE.PLAY_A_MATCH)}
            width="auto"
            color="secondary"
          >
            <StyledBallIcon fill={theme.palette.light[50]} />
            Play
          </StyledButton>
        )}

        <FooterNavButton
          onClick={() => navigate(ROUTE.GAMES)}
          title="Games"
          Icon={VsIcon}
          isActive={pathname === ROUTE.GAMES}
        />
      </StyledContainer>
    </Footer>
  );
};

export default observer(FooterNav);
