import ForgotPassword from 'pages/ForgotPassword';
import Games from 'pages/Games';
import Home from 'pages/Home';
import Login from 'pages/Login';
import Match from 'pages/Match';
import Player from 'pages/Player';
import PlayMatch from 'pages/PlayMatch';
import Profile from 'pages/Profile';
import Rate from 'pages/Rate';
import Register from 'pages/Register';
import RegisterSuccess from 'pages/RegisterSuccess';
import ResetPassword from 'pages/ResetPassword';
import Summary from 'pages/Summary';
import UnratedPlayers from 'pages/UnratedPlayers';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import RequireAuth from 'utils/RequireAuth';
import { ROUTE } from 'utils/routes';
import ForgotPasswordSuccess from './ForgotPasswordSuccess';

const AuthPages: React.FC = () => {
  return (
    <Routes>
      <Route path={ROUTE.HOME} element={<Home />} />
      <Route path={ROUTE.PROFILE} element={<Profile />} />
      <Route path={ROUTE.RATE} element={<Rate />} />
      <Route path={ROUTE.SUMMARY} element={<Summary />} />
      <Route path={`${ROUTE.PLAYERS}/:playerId`} element={<Player />} />
      <Route path={ROUTE.UNRATED_PLAYERS} element={<UnratedPlayers />} />
      <Route path={ROUTE.GAMES} element={<Games />} />
      <Route path={ROUTE.PLAY_A_MATCH} element={<PlayMatch />} />
      <Route path={`${ROUTE.MATCH}/:matchId`} element={<Match />} />

      <Route path="*" element={<Navigate to={ROUTE.HOME} />} />
    </Routes>
  );
};

const Pages: React.FC = () => {
  return (
    <Routes>
      <Route path={ROUTE.LOGIN} element={<Login />} />
      <Route path={ROUTE.REGISTER} element={<Register />} />
      <Route path={ROUTE.REGISTER_SUCCESS} element={<RegisterSuccess />} />
      <Route path={ROUTE.FORGOT_PASSWORD} element={<ForgotPassword />} />
      <Route
        path={ROUTE.FORGOT_PASSWORD_SUCCESS}
        element={<ForgotPasswordSuccess />}
      />
      <Route path={ROUTE.RESET_PASSWORD} element={<ResetPassword />} />

      <Route
        path="*"
        element={
          <RequireAuth redirectTo={ROUTE.LOGIN}>
            <AuthPages />
          </RequireAuth>
        }
      />
    </Routes>
  );
};

export default Pages;
