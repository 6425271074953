import Input, { IInputProps } from 'components/Input';
import { useField } from 'formik';
import React from 'react';

const FormikInput: React.FC<IInputProps> = (props) => {
  const [field, meta] = useField(props.name);

  return (
    <Input
      {...props}
      {...field}
      errorText={meta.touched ? meta.error : undefined}
    />
  );
};

export default FormikInput;
