import React, { ReactNode } from 'react';
import styled from 'styled-components';

const StyledButton = styled.button<{ selected?: boolean }>`
  color: ${(props) =>
    props.selected
      ? props.theme.palette.primary[500]
      : props.theme.palette.dark[200]};

  background: ${(props) =>
    props.selected
      ? props.theme.palette.primary[50]
      : props.theme.palette.light[50]};

  border: 2px solid
    ${(props) =>
      props.selected
        ? props.theme.palette.primary[500]
        : props.theme.palette.dark[50]};

  font-weight: ${(props) => (props.selected ? 600 : 400)};

  border-radius: 4px;
  display: inline-block;
  cursor: pointer;
`;

interface IToggleButtonProps {
  children: ReactNode;
  onClick: (() => void) | (() => Promise<void>);
  selected?: boolean;
  className?: string;
}

const ToggleButton: React.FC<IToggleButtonProps> = ({
  children,
  onClick,
  selected,
  className,
}) => {
  return (
    <StyledButton onClick={onClick} selected={selected} className={className}>
      {children}
    </StyledButton>
  );
};

export default ToggleButton;
