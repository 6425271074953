import { apiRequest } from 'api/axios';
import { IMatch } from 'types/Match';

export interface MatchInput {
  match: {
    date: string;
    noOfTeams: number;
    playerIds: number[];
  };
}

export interface MatchesInput {
  offset: number;
}

export interface MatchesResponse {
  matches: IMatch[];
  currentOffset: number;
  nextOffset: number;
  totalCount: number;
}

export interface FinishMatchInput {
  match: {
    winningTeamId: number;
  };
}

export const getMatches = (offset?: number) => {
  return apiRequest<MatchesInput, MatchesResponse>(
    'get',
    '/matches',
    offset ? { params: { offset } } : undefined
  );
};

export const createMatch = (data: MatchInput) => {
  return apiRequest<MatchInput, IMatch>('post', '/matches', { data });
};

export const getMatch = (id: number) => {
  return apiRequest<undefined, IMatch>('get', `/matches/${id}`);
};

export const deleteMatch = (id: number) => {
  return apiRequest<undefined, undefined>('delete', `/matches/${id}`);
};

export const finishMatch = (id: number, data: FinishMatchInput) => {
  return apiRequest<FinishMatchInput, undefined>(
    'post',
    `/matches/${id}/finish`,
    { data }
  );
};
