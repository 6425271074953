import { verifyEmail } from 'api/user';
import useAlert from 'hooks/useAlert';
import useQuery from 'hooks/useQuery';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTE } from 'utils/routes';

const useVerifyEmail = () => {
  const params = useQuery();
  const navigate = useNavigate();
  const alert = useAlert();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      const token = params.get('token');
      const email = params.get('email');
      if (token && email) {
        setLoading(true);

        try {
          await verifyEmail({
            verifyEmail: { email, verificationToken: token },
          });
          alert.success('Account successfully verified');
        } catch (e) {
          console.error('Verification failed');
          console.error(e);
          alert.error('Account verification failed');
        }
        navigate(ROUTE.LOGIN);

        setLoading(false);
      }
    })();
  }, [params]);

  return loading;
};

export default useVerifyEmail;
